
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  setup() {
    const state = reactive({
      list: [] as any,
      loaderEnabled: true,
      projet: 0,
      series: [] as any,

    });
    onMounted(async () => {
      let rEch = await getAxios("/getTicketProjets");
      state.list = rEch.results;
      if (state.list.length > 0){
        state.projet = state.list[0].pro_seq.toString();
        changeProjet();
      } 
      state.loaderEnabled = false;
    });

    const options = {
      //colors: ['#64b5f6', '#f8bbd0', '#f9d490', '#DAF7A6', '#FFC300'],
      
      chart: {
        type: "bar",
        stacked: true,
        stackType: "100%",
        events: {
          click: function(event, chartContext, config) {
            //console.log(event); 
          }
        },
        toolbar: {
          show: false,
        },
        animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    }
      },
      plotOptions: {
        bar: {
          horizontal: true
        },
      },
      stroke: {
        width: 4,
        curve: 'smooth',
        lineCap: 'butt',
        colors: ["#ffffff"]
      },
      title: {
        text: ""
      },
      xaxis: {
        categories: [""]
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val + " Ticket(s)";
          }
        }
      },
      fill: {
        opacity: 0.75,
      },
      legend: {
        position: "bottom",
        horizontalAlign: "left",
        offsetX: 0
      }

    };

    const router = useRouter();

    const clickChart = (event,chartContext, config) => {
      //console.log(state.series[config.seriesIndex].name);
      router.push({  name: "ticketWst", params: { status: state.series[config.seriesIndex].name } });
    };

    const changeProjet = async () => {
      let rEch = await getAxios("/getTicketProjet/" + state.projet);
      const newArraySeries = [] as any;
      
      rEch.results.forEach((el) => {
        el.categ = JSON.parse(el.categ);
        newArraySeries.push(el.categ);
      })      
      state.series = newArraySeries;
    };

    return {
      state,
      options,
      changeProjet,
      clickChart
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
